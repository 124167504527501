<template>
    <v-card>
        <v-row>
            <v-col>                  
                <s-crud
                    title="Observaciones"
                    :config="config"
                    edit
                    add                   
                    @save="save($event)"                
                    remove
                    :filter="filter"
                    search-input
                    height="170"
                    ref="crudPhenol"
                      >            
                    <template slot-scope="props">
                        <v-card>                          
                           <v-container>
                                <v-row >
                                     
                                    <v-col cols="12" >
                                        <s-textarea
                                            label="OBservacion"
                                             
                                            v-model="props.item.RobObs"
                                            />                                       
                                    </v-col>
                                </v-row>  
                           </v-container>                 
                        </v-card>
   
                            
                    </template>
                </s-crud>
      
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

import _visitApplication from "@/services/Technicalassistance/Visit/VisitRecomendationObsService.js";
 

    export default {
        components: { 
              
        },
        props: {
            Vst: { 
                type: Object, 
                default: 0 
            },
        },
        watch: {
            Vst(){
                this.filter.VstID = this.Vst.VstID;
                console.log("this.Vst.", this.Vst);
            }
        },
        data() {
            return {
                filter: { 
                    VstID: null,               
                },
                config: {
                    model: {
                        RobID: "ID"
                    },
                    service: _visitApplication,
                    headers: [
                        { text: 'ID', value: 'RobID' },
                        { text: 'Observacion', value: 'RobObs'},
                           
                    ],
                },               
            };
        },
        mounted(){
            
            console.log("mounted rem obsthis.Vst.", this.Vst);
            this.filter.VstID = this.Vst.VstID;
        },
        methods: {         
            save(recomObs){
                
                if (this.Vst.VstID == 0 || this.Vst.VstID == null) {
                    this.$fun.alert("Debe Seleccionar una Visita", "warning")
                    return;
                }
                if (recomObs.RobObs.length < 3) {
                    this.$fun.alert("La Obs debe tener minimo 3 caracteres", "warning")
                    return;
                }
                // let percur = parseFloat(phenol.StpPercentage) ;
 
                recomObs.VstID = this.Vst.VstID;
                recomObs.SecStatus = 1
 
                console.log("appl v", recomObs)
                recomObs.save().then((r) => {
                    this.$emit("reload");
                });
            },
             
        }
}
</script>