<template>
    <v-card>
        <v-row>
            <v-col>                  
                <s-crud
                    title="Evaluación Fitosanitaria"
                    :config="config"
                    edit
                    add                   
                    @save="save($event)"                
                    remove
                    :filter="filter"
                    search-input
                    height="170"
                    ref="crudPhenol" >            
                    <template slot-scope="props">
                        <v-card>                          
                           <v-container>
                            <v-row>
                                <v-col lg="5" class="pt-0 pb-0" cols="6">
                                <s-select-plagues
                                    :typeCultive="Vst.TypeCultive"                                   
                                    clearable                                     
                                    v-model="props.item.PlID"
                                    label="Enfermadad/Plaga/Maleza"/>
                                </v-col>
                                <v-col lg="4" class="pt-0 pb-0" cols="6">
                                <s-select-definition
                                    :def="1208"
                                    add
                                    clearable
                                    
                                    v-model="props.item.TypeOrganPlant"
                                    label="Organo"/>
                                </v-col>
                                <v-col lg="3" class="pt-0 right" cols="12">
                                <s-text
                                     
                                    label="Pocentaje"
                                    ref="PhePercentage"
                                    decimal
                                    v-model="props.item.PhePercentage"/>
                                </v-col>
                            </v-row>  
                           </v-container>                 
                        </v-card>
   
                            
                    </template>
                </s-crud>
      
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

import _visitApplication from "@/services/Technicalassistance/Visit/VisitPhytosanitaryEvaluationService";
import SSelectPlagues from "@/components/TecnicalAssistence/Plagues/SSelectPlagues";

    export default {
        components: { 
            SSelectPlagues,
        },
        props: {
            Vst: { 
                type: Object, 
                default: 0 
            },
        },
        watch: {
            Vst(){
                this.filter.VstID = this.Vst.VstID;
                console.log("this.Vst.", this.Vst);
            }
        },
        data() {
            return {
                filter: { 
                    VstID: null,               
                },
                config: {
                    model: {
                        PheID: "ID"
                    },
                    service: _visitApplication,
                    headers: [
                        { text: 'ID', value: 'PheID' },
                        { text: '', value: 'Line', sortable: false },
                        { text: 'Plaga', value: 'PlName', sortable: false },
                        { text: 'Organo', value: 'TypeOrganName', sortable: false},
                        { text: '%', value: 'PhePercentage', width: 50, sortable: false,align: 'center'},
                        { text: 'Acciones', value: 'Actions', align: 'center', width: '90',sortable: false},
                           
                    ],
                },
               
            };
        },
        mounted(){
            this.filter.VstID = this.Vst.VstID;
                console.log("mounted evalu sanit", this.Vst);
        },
        methods: {         
            save(evalu){
                 console.log("appl v", evalu)
                  
                if (this.Vst.VstID == 0 || this.Vst.VstID == null) {
                    this.$fun.alert("Debe Seleccionar una Visita", "warning")
                    return;
                }
              
                if (evalu.PlID == null) {
                    this.$fun.alert("Enfermedad/Plaga/Maleza es obligatorio", "warning")
                    return;
                }

                if (evalu.PhePercentage.length == 0) {
                    this.$fun.alert("Porcentaje no valido", "warning")
                    this.$refs.PhePercentage.focus();
                    return;
                }
                if (evalu.PhePercentage < 0 || evalu.PhePercentage > 100) {
                    this.$fun.alert("Porcentaje no valido", "warning")
                    return;
                }
               

                evalu.VstID = this.Vst.VstID;
                evalu.SecStatus = 1
               
                 
                evalu.save().then((r) => {
                    this.$emit("reload");
                });
            },
            
        }
}
</script>