<template>
    <v-card>
        <v-row>
            <v-col>                  
                <s-crud
                    title="Fenologia"
                    :config="config"
                    edit
                    add                   
                    @save="save($event)"                
                    remove
                    :filter="filter"
                    search-input
                    height="170"
                    ref="crudPhenol"
                    @getFullData="getFullData($event)" >            
                    <template slot-scope="props">
                        <v-card>                          
                           <v-container>
                                <v-row >
                                    <v-col cols="2" >
                                        <s-select-definition
                                            label="Etapa Fenologica"
                                            :def="1207"
                                            add
                                            v-model="props.item.TypeStatePhenological"/>                                      
                                    </v-col>
                                    <v-col cols="2" >
                                        <s-text
                                            label="%"
                                            decimal
                                            v-model="props.item.StpPercentage"
                                            />                                       
                                    </v-col>
                                </v-row>  
                           </v-container>                 
                        </v-card>
   
                            
                    </template>
                </s-crud>
      
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

import _visitApplication from "@/services/Technicalassistance/Visit/VisitPhenologicalService.js";
 

    export default {
        components: { 
              
        },
        props: {
            Vst: { 
                type: Object, 
                default: 0 
            },
        },
        watch: {
            Vst(){
                this.filter.VstID = this.Vst.VstID;
                console.log("this.Vst.", this.Vst);
            }
        },
        data() {
            return {
                filter: { 
                    VstID: null,               
                },
                config: {
                    model: {
                        StpID: "ID"
                    },
                    service: _visitApplication,
                    headers: [
                        { text: 'ID', value: 'StpID' },
                        { text: 'Etapa Fenologica', value: 'TypeStagePheologicalName'},
                        { text: 'Porcentaje (%)', value: 'StpPercentage' },
                           
                    ],
                },
                totalPercent: 0,
                datosFen: []
            };
        },
        mounted(){
            console.log("mounted phenog")
            console.log("this.Vst.", this.Vst);
            this.filter.VstID = this.Vst.VstID;
        },
        methods: {         
            save(phenol){
                
                if (this.Vst.VstID == 0 || this.Vst.VstID == null) {
                    this.$fun.alert("Debe Seleccionar una Visita", "warning")
                    return;
                }
                if (phenol.StpPercentage == 0 || phenol.StpPercentage == "") {
                    this.$fun.alert("El Porcentaje debe ser mayor a 0", "warning")
                    return;
                }
                // let percur = parseFloat(phenol.StpPercentage) ;

                let percur = parseFloat(phenol.StpPercentage) +  parseFloat(this.totalPercent);
                console.log("appl percur", percur)

                if (percur > 100 && phenol.StpID == 0) {
                    this.$fun.alert("La suma del porcentaje debe ser menor a 100", "warning")
                    return;
                }
                if (phenol.StpID > 0) {
                console.log("iff phenol.StpID", phenol.StpID)

                    this.totalPercent = 0;
                    this.datosFen.forEach(element => {
                        
                        if(element.StpID != phenol.StpID){
                            this.totalPercent += parseFloat(element.StpPercentage);
                            console.log("element.StpID  forrrr", element.StpPercentage)
                        }
                    });

                    this.totalPercent += parseFloat(phenol.StpPercentage);
                    console.log("this.totalPercent utlimo de if", this.totalPercent)

                    if (this.totalPercent > 100) {
                        this.$fun.alert("La suma del porcentaje debe ser menor a 100", "warning")
                        return;
                    }
                }

                phenol.VstID = this.Vst.VstID;
                phenol.SecStatus = 1
 
                console.log("appl v", phenol)
                phenol.save().then((r) => {
                    this.$emit("reload");
                });
            },
            getFullData(data){
                this.totalPercent = 0;
                console.log("data scrud", data)
                 data.data.forEach(element => {
                   this.totalPercent += element.StpPercentage;
                });
                this.datosFen = data.data;

                console.log("this.totalPercent", this.totalPercent)

            }
        }
}
</script>