<template>
  <div>
    <v-row>
      <v-col>
        <s-crud
          title="Visitas Técnicas"
          :config="config"
          edit
          add
          height="170"
          @save="save($event)"
          @rowSelected="rowSelected($event)"
          remove
          :filter="filter"
          search-input
          number-page="5"
          ref="crudVist" >
          <template v-slot:filter>
            <v-card class="pb-2   pl-2 pr-2">
              <v-row >
              
                <v-col sm="2" lg="2" cols="12" > 
                  <s-date  
                    label="Fecha Inicio" 
                    v-model="filter.VstBeginDate"/>
                </v-col>
                <v-col sm="2" lg="2" cols="12"  > 
                  <s-date  
                    label="Fecha Fin" 
                    v-model="filter.VstEndDate"/>
                </v-col>
                <v-col sm="4" lg="4" cols="12">           
                  <s-select-supervice
                    clearable
                    label="Supervisor"
                    v-model="filter.VstSupervisorPrsID"
                    full /> 
                </v-col> 
              </v-row>
            </v-card>
          </template>
          <template slot-scope="props">
            <v-row   class="mt-0 mb-0 ml-5 mr-5">
              <v-col sm="2" lg="2" cols="12" class="pb-0 pt-1"> 
                <s-date  
                  label="Fecha" 
                  v-model="props.item.VstDate"
                  @input="inputDateVisit($event)"
                  :max="$fun.getDate(0)" /> 
              </v-col>
                
              <v-col sm="2" lg="2" cols="12" class="pt-1 pb-0" >
                <s-text                  
                  label="Serie"
                  number
                  v-model="props.item.VstSerie"/>
              </v-col>
              <v-col  sm="2" lg="2" cols="12" class="pt-1 pb-0">
                <s-text                  
                  label="Numero"
                  v-model="props.item.VstNumber"
                  number/>
              </v-col>
              <v-col sm="4" lg="4" cols="12" class="s-col-form">           
                <s-select-supervice
                  clearable
                  label="Supervisor"
                  v-model="props.item.VstSupervisorPrsID"
                  full /> 
              </v-col>  
              <v-col sm="2" lg="2" cols="12" class="pt-1 pb-0" >
                <s-text                  
                  label="ID Programación"
                  number
                  v-model="props.item.TvID"/>
              </v-col>   
              <v-col sm="2" lg="2" cols="12" class="pb-0 pt-1" >
                <s-search-lot-by-cod-senasa
                  @searchSenasa="searchSenasa($event)"
                  />
              </v-col>
            
              <v-col sm="2" lg="2" cols="12" class="pt-1 pb-0" >
                <s-text
                  ref="refCodSenasa"
                  readonly
                  label="ID Lote"
                  v-model="item.FltID" />
              </v-col>
              <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0" >
                <s-text
                  ref="refCodSenasa"
                  readonly
                  label="Lote"
                  v-model="item.FltName"/>
              </v-col>
              <v-col sm="4" lg="3" cols="12" class="pt-1 pb-0" >
                <s-text
                  ref="refCodSenasa"
                  readonly
                  label="Nombre de Fundo"
                  v-model="item.FagName"/>
              </v-col>
              <v-col sm="4" lg="3" cols="12" class="pt-1 pb-0">
                <s-text
                  ref="refCodSenasa"
                  readonly
                  label="Productor"
                  v-model="item.PrdCardName"/>
              </v-col>
              <v-col sm="4" lg="4" cols="12" class="pt-1 pb-0" >
                <s-text
                  ref="refCodSenasa"
                  readonly
                  label="Direccion Fundo"
                  v-model="item.FagAddress"/>
              </v-col>
            
              <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0">
                <s-text
                  ref="refCodSenasa"
                  readonly
                  label="Zona"
                  v-model="item.ZonDescription"/>
              </v-col>
              <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0" >
                <s-text
                  ref="refCodSenasa"
                  readonly
                  label="Sub Zona"
                  v-model="item.SzoDescription"/>
              </v-col>
            
              <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0" >
                <s-text
                  ref="refCodSenasa"
                  readonly
                  label="Variedad"
                  v-model="item.VrtName"/>
              </v-col>
              <v-col sm="4" lg="2" cols="12" class="pt-1 pb-0">
                <s-text
                  ref="refCodSenasa"
                  readonly
                  label="Tipo de Cultivo"
                  v-model="item.TypeCropName"/>
              </v-col>
            </v-row>
            <v-row class="mb-0 ml-5 mr-5">
              <v-col lg="2" class="pb-0 pt-0 "  >
                  <s-select-campaign-area
                      clearable
                      label="Campaña"
                      v-model="props.item.GenID"
                      typeArea="1"
                      full
                  ></s-select-campaign-area>
              </v-col>
              <v-col lg="2" class=" "  >
                  <s-switch
                      label="Firma Productor"
                      v-model="props.item.PrdSignature"
                       
                  ></s-switch>
              </v-col>
              <v-col lg="12" class="pb-0 pt-0 "  v-if="messageFarmLot != ''">
                 
                   <v-alert
                      type="warning"
                      
                      variant="tonal"
                    >{{ messageFarmLot }}</v-alert>
              </v-col>
            </v-row> 
            <v-row class="mt-0 mb-0 ml-5 mr-5">
              <v-col>
                <s-textarea 
                  label="Observaciones"
                  v-model="props.item.VstObservation" />
              </v-col>
            </v-row>
            
          </template>

          <template v-slot:PrdSignature = "{ row }">
            <v-chip x-small :color="row.PrdSignature ? 'success' : 'warning'">
              {{ row.PrdSignature ? 'SI' : 'NO' }}
            </v-chip>
          </template>
          <template v-slot:CountApplications = "{ row }">
            <v-chip x-small :color="row.CountApplications > 0 ? 'success' : 'warning'">
              {{ row.CountApplications }}
            </v-chip>
          </template>
          <template v-slot:CountPhenological = "{ row }">
            <v-chip x-small :color="row.CountPhenological > 0 ? 'success' : 'warning'">
              {{ row.CountPhenological }}
            </v-chip>
          </template>
          <template v-slot:CountActiCult = "{ row }">
            <v-chip x-small :color="row.CountActiCult > 0 ? 'success' : 'warning'">
              {{ row.CountActiCult }}
            </v-chip>
          </template>
          <template v-slot:CountPhytoEval = "{ row }">
            <v-chip x-small :color="row.CountPhytoEval > 0 ? 'success' : 'warning'">
              {{ row.CountPhytoEval }}
            </v-chip>
          </template>
        </s-crud>
      
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!--@change=changeTab($event) @change="clickInTab(item)" --->
        <v-tabs centered  height="35"  v-model="currentItem">
          <v-tab  v-for="itemt in itemsCab" :key="itemt" :href="'#tab-' + itemt"  > 
              {{ itemt }}               
                   
          </v-tab>
        </v-tabs>
        <v-tabs-items 
          v-model="currentItem" 
          style="padding: 10 10px 10px 0px;">
          <v-tab-item v-for="itemt in itemsCab" :key="itemt" :value="'tab-' + itemt">
            <v-card v-if='itemt=="Evaluaciones"' class="pb-5 pt-5">                                       
              <v-tabs   height="35"  v-model="currentItemOne" >
                <v-tab  v-for="itemt in itemsOne" :key="itemt" :href="'#tab-' + itemt"  > 
                    {{ itemt }}               
                    <v-chip x-small color="info">
                      {{  itemt == "Aplicaciones" ? item.CountApplications : "" }}
                      {{  itemt == "Fenologia" ? item.CountPhenological : "" }}
                      {{  itemt == "Evaluación Fitosanitaria" ? item.CountPhytoEval : "" }} 
                      {{  itemt == "Actividades Realizadas" ? item.CountActivi : "" }}
                      {{  itemt == "Evaluación de Instalaciones" ? item.CountInstallEvaluat : "" }} 
                       
                    </v-chip>
                                  
                </v-tab>
              </v-tabs>
              <v-tabs-items 
                v-model="currentItemOne" 
                style="padding: 10 10px 10px 0px;">
                <v-tab-item v-for="itemt in itemsOne" :key="itemt" :value="'tab-' + itemt">
              
                  <v-card v-if='itemt=="Aplicaciones"' class="pb-5 pt-5">                       
                    <visit-applications
                      :Vst="item"
                      @reload="reload()"
                    />
                  </v-card>
                  <v-card v-if='itemt=="Actividades Realizadas"' class="pb-5 pt-5">                       
                    <visit-performed-activities 
                      v-if='itemt=="Actividades Realizadas"'
                      :Vst="item"
                      @reload="reload()"
                    />
                  </v-card>
                  <v-card v-if='itemt=="Fenologia"' class="pb-5 pt-5">                       
                    <visit-phenological
                      :Vst="item"
                      @reload="reload()"
                    />
                  </v-card>
                  <v-card v-if='itemt=="Evaluación Fitosanitaria"' class="pb-5 pt-5">                       
                    <visit-phytosanitary-evaluation
                      :Vst="item"
                      @reload="reload()"
                    />
                  </v-card> 
                  <v-card v-if='itemt=="Evaluación de Instalaciones"' class="pb-5 pt-5">                       
                    <visit-installation-evaluation
                      :Vst="item"
                      @reload="reload()"
                    />
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
            <v-card v-if='itemt=="Recomendaciones"' class="pb-5 pt-5">                                       
              <v-tabs   height="35"  v-model="currentItemTwo" >
                <v-tab  v-for="itemt in itemsTwo" :key="itemt" :href="'#tab-' + itemt"  > 
                    {{ itemt }}               
                    <v-chip x-small color="info">  
                      {{  itemt == "Recomendaciónes" ? item.CountRecomen : "" }}
                      {{  itemt == "Actividades culturales" ? item.CountActiCult : "" }}  
                      {{  itemt == "Observaciones" ? item.CountRecomObs : "" }}   
                    </v-chip>
                                  
                </v-tab>
              </v-tabs>
              <v-tabs-items 
                v-model="currentItemTwo" 
                style="padding: 10 10px 10px 0px;">
                <v-tab-item v-for="itemt in itemsTwo" :key="itemt" :value="'tab-' + itemt">
               
                  <v-card v-if='itemt=="Actividades culturales"' class="pb-5 pt-5">                       
                    <visit-activity-cultural
                       
                      :Vst="item"
                      @reload="reload()"
                    />
                  </v-card>
                  <v-card v-if='itemt=="Recomendaciónes"' class="pb-5 pt-5">                       
                    <visit-recomendation               
                      :Vst="item"
                      @reload="reload()"
                    />
                  </v-card>
                  <v-card v-if='itemt=="Observaciones"' class="pb-5 pt-5">                       
                    <visit-recomendation-obs               
                      :Vst="item"
                      @reload="reload()"
                    />
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-tab-item>
          </v-tabs-items>



      </v-col>
    </v-row>
    <!-- <v-dialog
    
      v-model="dialogVisit"
      permanent
      v-if="dialogVisit"
      persistent
      width="80%"
    >
      <visit-edit
        @close="closeDialogVisit()"
        :isEditabled ="isEdit"
        :visitSchedule="visitEdit"
      ></visit-edit>
    </v-dialog> -->
     <!-- @add="addVisit()" add-no-function  @edit="editVist($event)"@add="addVisit()" -->
  </div>
</template>


<script>
import VisitEdit from "@/views/TechnicalAssistance/Visits/VisitEdit";
import VisitApplications from "@/views/TechnicalAssistance/Visits/VisitApplications";
import VisitPhenological from "@/views/TechnicalAssistance/Visits/VisitPhenological";
import VisitPhytosanitaryEvaluation from "@/views/TechnicalAssistance/Visits/VisitPhytosanitaryEvaluation";
import VisitActivityCultural from "@/views/TechnicalAssistance/Visits/VisitActivityCultural";
import VisitRecomendation from "@/views/TechnicalAssistance/Visits/VisitRecomendation";
import VisitPerformedActivities from "@/views/TechnicalAssistance/Visits/VisitPerformedActivities";
import VisitInstallationEvaluation from "@/views/TechnicalAssistance/Visits/VisitInstallationEvaluation";
import VisitRecomendationObs from "@/views/TechnicalAssistance/Visits/VisitRecomendationObs"; 

import _sServices from "@/services/Technicalassistance/Visit/Visit.js";
import SSelectSupervice from "@/components/HarvestSchedule/SSelectSupervice";
import SSearchLotByCodSenasa from "@/components/HarvestSchedule/sSearchLotByCodSenasa.vue";
import SSelectCampaignArea from "../../../components/Utils/SSelectCampaignArea.vue";

export default {
  name: "RequirementServiceTasPaymentCategoryService",
  components: { 
    VisitEdit,
    VisitApplications,
    SSelectSupervice,
    SSearchLotByCodSenasa,
    VisitPhenological,
    VisitPhytosanitaryEvaluation,
    VisitActivityCultural,
    VisitRecomendation,
    VisitPerformedActivities,
    VisitInstallationEvaluation,
    VisitRecomendationObs,
    SSelectCampaignArea
  },
  props: {
    PlID: { type: Number, default: 0 },
  },

  data() {
    return {
      currentItem: 'tab-Funciones',   
      currentItemOne: 'tab-Evaluaciones',   
      currentItemTwo: 'tab-Recomendaciones',   
      itemsOne: ['Aplicaciones', 'Fenologia', 'Evaluación Fitosanitaria', 'Actividades Realizadas', 'Evaluación de Instalaciones'],
      itemsTwo: ['Recomendaciónes', 'Actividades culturales', 'Observaciones'],
      itemsCab: ['Evaluaciones', 'Recomendaciones'],
      item: {
      },
      isEdit : false,
      visitEdit: {},
      dialogVisit: false,
      filter: { VstBeginDate: null, VstEndDate: null },
      messageFarmLot: "",
      dateAlert: "1990-01-01",
    };
  },

  computed: {
    config() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          VstID: "ID",
          VstDate: "date",
          DedDescription: "string",
          Estado: "string",
          PcStatus: "status",
          CountApplications: "number",
          CountPhenological: "number",
          CountActiCult: "number",
          CountPhytoEval: "number",
          PrdSignature: "number",
          //
        },
        service: _sServices,
        headers: [
          //Vista de tabla para admin
          { text: "ID", value: "VstID", sortable: false },
          { text: "Fecha", value: "VstDate", sortable: false },
          { text: "Serie", value: "VstSerie", sortable: false },
          { text: "Numero Doc.", value: "VstNumber", sortable: false },
          { text: "Supervisor", value: "NtpSupervisorName", sortable: false },
          // { text: "Aplic.", value: "CountApplications", sortable: false }, 
          // { text: "Fenologia.", value: "CountPhenological", sortable: false }, 
          // { text: "Act. Cult", value: "CountActiCult", sortable: false },  
          // { text: "Eval Fisi.", value: "CountPhytoEval", sortable: false },  
          { text: "ID Lote", value: "FltID", sortable: false },
          { text: "Lote", value: "FltName", sortable: false },
          { text: "Fundo", value: "FagName", sortable: false },
          { text: "Zona", value: "ZonDescription", sortable: false }, 
          { text: "Sub Zona", value: "SzoDescription", sortable: false }, 
          { text: "Firma Prod.", value: "PrdSignature", sortable: false }, 
          { text: "Campaña", value: "CmaName", sortable: false }, 
        ],
      };
    },
  },

  methods: {
    inputDateVisit(date){
      
      this.dateAlert = date;

      

      if (this.item.FltID) {
       

        var lot = this.item;
        lot.VstDate = date;
        this.getVisitFarmLot(lot);
      }
    },
    reload(){
      this.$refs.crudVist.refresh();
    },
    searchSenasa(lot) {
      
      
      this.item = lot;
      
      this.item.TypeCropName = lot.TypeCropText;
      this.item.VrtName = lot.VrtDescription;
      this.item.ZonDescription = lot.ZonIDText;
      this.item.SzoDescription = lot.SzoIDText;

      lot.VstDate = this.dateAlert;
     
     
      this.getVisitFarmLot(lot);
    },
    getVisitFarmLot(lot){
      this.messageFarmLot = "";
      _sServices.getVisitFarmLot(lot, this.$fun.getUserID()).then((r) => {

          if (r.status == 200) {
            
            if (r.data.VstID > 0) {
              this.messageFarmLot = "Ultima Visita del Productor: " + this.$fun.formatDateView(r.data.VstDate) + ", Dias: " +  r.data.DaysLastDateVisit;
              
            }
          }
       });
    },
    rowSelected(rows) {
      this.item = {};
      if (rows.length > 0) {
        this.visitEdit = rows[0];
        
        this.item = rows[0];
        this.item.GenID = this.item.CmaID;
        console.log('item', this.item);
      }
    },

    editVist(item) {
      this.isEdit = true;
      this.dialogVisit = true;
      this.visitEdit = item;
    },

    addVisit() {
      
      // this.isEdit = false;
      // this.dialogVisit = true;
      // this.visitEdit = {};
      
    },

    closeDialogVisit () {
      this.dialogVisit = false;
      this.$refs.crudVist.refresh();
    },

    save(visit) {
      
      
      if (visit.VstSerie.length == 0) {
        this.$fun.alert("Debe ingresar una Serie", "warning")
        return;
      }
      if (visit.VstNumber.length == 0) {
        this.$fun.alert("Debe ingresar un numero de documento", "warning")
        return;
      }

      let fltid = this.item.FltID ? parseInt(this.item.FltID) : 0;
      if (fltid == 0 || fltid == undefined) {
        this.$fun.alert("Debe seleccionar un Lote", "warning")
        return;
      }
 
      if (visit.VstSupervisorPrsID == null) {
        this.$fun.alert("Debe seleccionar un Supervisor", "warning")
        return;
      }

      visit.FltID = fltid;
      visit.PlID = this.PlID;
      visit.ZonID = this.item.ZonID;
      visit.SzoID = this.item.SzoID;

      visit.CmaID = visit.GenID;
    
      
      visit.save().then((r) => {
        this.item = {};

      });
    },
  },
  watch: {},
  created() {},
  mounted(){
    
  }
};
</script>
