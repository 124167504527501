<template>
    <v-card  class="pb-5 pt-5 pr-5 pl-5">                    
        
     
       <v-row v-if="keyTypesa">
       <v-col lg="2" class="pb-0 pt-0 " v-for="i in itemsTypeInstalEval">
         
           <v-switch
               :label="i.DedDescription"
               v-model="i.IneStatus" color="primary"
               @click ="saveswitchv(i)"
           ></v-switch>
       </v-col>
       </v-row>                
    </v-card>
</template>

<script>

import _visitApplication from "@/services/Technicalassistance/Visit/VisitInstallationEvaluationService";
import SSelectPlagues from "@/components/TecnicalAssistence/Plagues/SSelectPlagues";
import _sDefinition from "@/services/General/DefinitionService.js";

   export default {
       components: { 
           SSelectPlagues,
       },
       props: {
           Vst: { 
               type: Object, 
               default: 0 
           },
       },
       watch: {
           Vst(){
                this.loadDef();
               
            //    this.loadEval(this.Vst.VstID);
           },
           keyTypesa(){
            //    console.log("en la mira")
           }
       },
       data() {
           return {
               statss: true,
               filter: {},
               itemsTypeInstalEval: [],
               keyTypesa : false
           };
       },
       mounted(){
           this.loadDef();

       },
       methods: {    
           loadDef(){
            this.keyTypesa = false;
               _sDefinition.definitiongroup(1209, 0, this.$fun.getUserID()).then((r) => {

                
                    r.data.forEach((item) => {
                           
                           item.IneStatus = false;
                           item.IneID = 0;
                    });
                       
                   this.itemsTypeInstalEval = r.data;
                   // this.itemsCountInstallations = this.itemsTypeInstallations.length;

                   this.loadEval(this.Vst.VstID);
                //    --SAMIR comment
               });
           },
           saveswitchv(actcu){
               if (this.Vst.VstID == 0 || this.Vst.VstID == null) {
                   this.$fun.alert("Debe Seleccionar una Visita", "warning")
                   return;
               }
             
               actcu.VstID = this.Vst.VstID;
               actcu.TypeInstallation = actcu.DedValue;
               actcu.SecStatus = 1;
               actcu.AcuObservation = "";
               // actcu.AcuID = 0;
               console.log("appl v", actcu)

               let obj = {
                   VstID: this.Vst.VstID,
                   TypeInstallation: actcu.DedValue,
                   SecStatus: 1,
                  
                   IneID: actcu.IneID,
                   IneStatus: actcu.IneStatus ? 1 : 0,
                   UsrCreateID: this.$fun.getUserID(),
                   UsrUpdateID:  this.$fun.getUserID(),
               }
               console.log("obj sav", actcu)
              
               _visitApplication.save(obj , this.$fun.getUserID()).then((r) => {
                   if (r.status == 200) {
                       this.$fun.alert("Guardado correctamente", "success")

                       this.loadDef();
                   }
               });
           },
           loadEval(vstID){
               
               console.log("loadActv", vstID)
               _visitApplication.list({ VstID: vstID } , this.$fun.getUserID()).then((r) => {
                   if (r.status == 200) {
                        
                       console.log("data de actividades", r)

                        this.itemsTypeInstalEval.forEach((item) => {
                          // item.TypeActivity = item.DedValue;
                         
                           item.SecStatus = 1;
                           item.UsrCreateID = this.$fun.getUserID();
                        //    console.log("data de actividades Iitem", item)

                           r.data.forEach(element => {

                               if (item.DedValue == element.TypeInstallation) {
                                //    item.AcuStatus = true;
                                item.IneID = element.IneID;
                                // item.IneID = 5;
                                item.IneStatus = element.IneStatus == 1 ? true : false;
                                
                                console.log("coinciden", item)
                                console.log("coinciden bd", element)
                               }
                           });
                       });
                       
                    //    this.itemsTypeInstalEval = itemsIa;
                       
                        this.keyTypesa = true;
                       console.log("this.itemsTypeInstalEval.", this.itemsTypeInstalEval)
                   }
               });
           }
       }
}
</script>