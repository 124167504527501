<template>
    <v-card>
        <v-row>
            <v-col>                  
                <s-crud
                    title="Recomendaciónes"
                    :config="config"
                    edit
                    add                   
                    @addEvent="addRecom()"                   
                    @save="save($event)"                
                    remove
                    :filter="filter"
                    search-input
                    height="170"
                    ref="crudVisitRecom"
                    @rowSelected="rowSelected($event)" >            
                    <template slot-scope="props">
                        <v-card>                          
                           <v-container>
                               
                                <v-row >
                                    <v-col sm="3" lg="3" class="pb-0" cols="12">
                                        <s-select-definition
                                            :def="1214"                                         
                                            label="Tipo de Aplicación"
                                            return-object
                                            v-model="newRecomTa"
                                            @input="inputTypeAppl($event)" />
                                    </v-col>
                                    <v-col sm="3" lg="3" class="pb-0" cols="12">
                                        <s-select-plagues
                                            return-object
                                            clearable
                                            :typeCultive="Vst.TypeCultive"
                                            label="Enfermedad/Plaga/Maleza"
                                            v-model="newRecomPl"
                                            @input="inputPlague($event)" />
                                    </v-col>
                                    <v-col sm="2" lg="2" class="pb-0" cols="12">
                                            <!--  :PlID="-->
                                        <s-select-listsupplies
                                            full
                                            label="Producto"
                                            return-object 
                                            v-model="newRecomLs" 
                                            @input="inputProductRecomendation($event)"/>
                                    </v-col>
                                    <v-col sm="2" lg="2" class="pb-0" cols="12">
                                        <s-text
                                            decimal
                                            label="Dosis"
                                            ref="DoceRecomendation"
                                            v-model="newRecom.RcmDose"/>
                                    </v-col>
                                    <v-col sm="2" lg="2" class="pb-0" cols="12">
                                        <s-select-definition
                                            label="Orden"
                                            :def="1441"
                                            v-model="newRecom.TypeOrder"
                                            add/>
                                        
                                        </s-select-definition>
                                    </v-col>
                                    <!-- TypeUnit
                                    <v-col sm="4" lg="2" class="pb-0" cols="12">
                                        <s-text
                                            number                                         
                                            ref="refRcmGatheringZoneNumber"
                                            label="N° Zona acopio"/>                                        
                                    </v-col> -->
                                    <v-col sm="2" lg="2" class="pb-0" cols="12">                                       
                                        <v-btn
                                            color="primary"
                                            x-small
                                            class="mr-3 mt-1"
                                            elevation="5"
                                            @click="addRecomendation()"
                                            fab>
                                            <v-icon height="26" style="font-size: 16px !important"
                                                >fas fa-plus</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>  
                                <v-row>
                                    <v-col>
                                    <v-data-table
                                        :items="itemsRecomendation"
                                        :headers="[
                                        { text: '', value: 'Line', width: '70' },
                                        { text: 'Detalles', value: 'viewDetails' , width:'70'},
                                        { text: 'Tipo Aplicación', value: 'TypeApplicationName',width: '120'},
                                        { text: 'Enfermedad/Plaga/Maleza', value: 'PlName',width: '200'},
                                        { text: 'Producto', value: 'LsDescription' },
                                        { text: 'Dosis', value: 'RcmDose', width: '120' },
                                        { text: 'Acciones', value: 'Actions',align: 'center',width: '90',sortable: false},
                                        ]"
                                        dense
                                        :items-per-page="5">
                                        <template v-slot:item.Actions="{ item }">
                                        <v-btn
                                            @click="clickDeleteRecomendations(item)"
                                            x-small
                                            color="error"
                                            elevation="0"
                                            block>
                                            <v-icon style="font-size: 16px !important"
                                            >fas fa-times</v-icon>
                                        </v-btn>
                                        </template>
                                        <!-- <template v-slot:item.viewDetails="{ item }">
                                            <v-btn class="ma-2"
                                                    x-small
                                                    @click="viewDetailRecomendations(item)"
                                                    outlined
                                                    color="indigo">
                                                <v-icon small >fas fa-eye theme--light</v-icon>
                                            </v-btn>
                                        </template> -->

                                    </v-data-table>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <s-textarea  
                                            v-model="props.item.RchNote"
                                            label="Recomendacion"/>
                                    </v-col>
                                </v-row>
                           </v-container>                 
                        </v-card>
   
                            
                    </template>

                    <template v-slot:CountProduct = "{ row }">
                        <v-chip x-small :color="row.CountProduct > 0 ? 'success' : 'warning'">
                        {{ row.CountProduct }}
                        </v-chip>
                    </template>
                </s-crud>
      
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

import _visitApplication from "@/services/Technicalassistance/Visit/VisitRecomendationHeadService.js";
import SSelectPlagues from "@/components/TecnicalAssistence/Plagues/SSelectPlagues";
import sSelectListsupplies from "@/components/TecnicalAssistence/ListSupplies/SSelectListsupplies";

    export default {
        components: { 
            SSelectPlagues,
            sSelectListsupplies,
        },
        props: {
            Vst: { 
                type: Object, 
                default: 0 
            },
        },
        watch: {
            Vst(){
                this.filter.VstID = this.Vst.VstID;
                console.log("this.Vst.", this.Vst);
                this.itemsRecomendation = [];
                this.itemsDeleteRecomendation = [];
            }
        },
        data() {
            return {
                filter: { 
                    VstID: null,               
                },
                config: {
                    model: {
                        RchID: "ID",
                        CountProduct: "number"
                    },
                    service: _visitApplication,
                    headers: [
                        { text: 'ID', value: 'RchID' },
                        { text: 'Cantidad de Productos', value: 'CountProduct' },
                        { text: 'Productos', value: 'ConcatProducts' }, 
                        { text: 'Nota', value: 'RchNote'},
                           
                    ],
                },
                itemsRecomendation: [],
                itemsDeleteRecomendation: [],
                newRecom: {},
                newRecomTa: {},
                newRecomLs: {},
                newRecomPl: {},
            };
        },
        mounted(){
            this.filter.VstID = this.Vst.VstID;
                console.log("mounted recomendaci", this.Vst);
        },
        methods: {   
            addRecom(){
                this.itemsRecomendation = [];
                this.itemsDeleteRecomendation = [];
            },
            rowSelected(rows){
                console.log("row selected", rows)
                if(rows.length == 0)  {
                    return;
                }
                _visitApplication.listRecomendation(rows[0], this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        console.log("r data recom", r.data)

                        this.itemsRecomendation = r.data;
                    }
                })
            },    
            inputPlague(plague){
                console.log("este s el plague", plague)
                if (plague) this.newRecom.PlName = plague.PlDescription;
                if (plague) this.newRecom.PlID = plague.PlID;
            },
            inputTypeAppl(typeapl) {
                console.log("este s el typeapl", typeapl)
                // if (typeapl) this.newRecom.TypeApplicationName = typeapl.DedDescription;
                // if (typeapl) this.newRecom.TypeApplication = typeapl.DedValue;
            },
            inputProductRecomendation(product) {
                console.log("este s el prodciuto", product)
                if (product) this.newRecom.TypeUnit = product.LsUnitID;
                if (product) this.newRecom.LsDescription = product.LsDescription;
            },
            save(phenol){
                if (this.Vst.VstID == 0 || this.Vst.VstID == null) {
                    this.$fun.alert("Debe Seleccionar una Visita", "warning")
                    return;
                }
              
                phenol.VstID = this.Vst.VstID;
                phenol.SecStatus = 1
                // phenol.TasVisitRecomendations = this.itemsRecomendation;
                phenol.TasVisitRecomendations = [...this.itemsRecomendation, ...this.itemsDeleteRecomendation];
                
                console.log("this.itemsDeleteRecomendation v", this.itemsDeleteRecomendation)
                console.log("appl v", phenol)
                 
                phenol.save().then((r) => { 
                    this.itemsRecomendation = [];
                    this.itemsDeleteRecomendation = [];
                    this.$refs.crudVisitRecom.refresh();
                    this.$emit("reload");
                });
            },
            addRecomendation() {

                if (this.newRecom.RcmDose == "") {
                    this.$refs.DoceRecomendation.error("Ingrese Dosis Recomendada", "warning");
                    return;
                }

                this.newRecom.LsID = this.newRecomLs.LsID;
                this.newRecom.TypeUnit = this.newRecomLs.LsUnitID;
                this.newRecom.LsDescription = this.newRecomLs.LsDescription;

                this.newRecom.TypeApplicationName = this.newRecomTa.DedDescription;
                this.newRecom.TypeApplication = this.newRecomTa.DedValue;

                if (this.newRecomPl) {
                    this.newRecom.PlName = this.newRecomPl.PlDescription ?? "";
                    this.newRecom.PlID = this.newRecomPl.PlID;
                }else{
                    this.newRecom.PlName = "";
                    this.newRecom.PlID = 0;
                }
                
                // if(this.numberInstallations4 == 4 ) {
                //     if(this.recomentationObj.RcmGatheringZoneNumber <= 0 ) {
                //         this.$refs.refRcmGatheringZoneNumber.error("Ingrese zona recomendada");
                //         return;
                //     }
                // }
                this.newRecom.VstID = this.Vst.VstID;
                this.newRecom.Line = this.itemsRecomendation.length + 1;
                this.newRecom.SecStatus = 1;
                this.newRecom.UsrCreateID = this.$fun.getUserID();

                console.log('item push',this.newRecom )

                this.itemsRecomendation.push(this.newRecom);

                this.newRecom = {};
                console.log('data recomendation a gurdar',this.itemsRecomendation )
            },
            clickDeleteRecomendations(item) {
                
                if(this.itemsRecomendation.length > 0){
                    console.log('item DELETE push', item)
                if(item.RchID > 0){

                    this.itemsDeleteRecomendation.push(
                    this.itemsRecomendation.filter(x=> x.Line == item.Line)[0],
                    );

                    this.itemsDeleteRecomendation.forEach(element => {
                    element.SecStatus = 0;
                    });
                }

                this.itemsRecomendation = this.itemsRecomendation.filter(
                    (x) => x.Line != item.Line
                );
                this.orderRecomendations();
                }
            },
            orderRecomendations() {
                let i = 0;
                this.itemsRecomendation.map((e) => {
                    i++;
                    e.Line = i;
                });
            },
        }
}
</script>