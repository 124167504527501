<template>
     <v-card  class="pb-5 pt-5 pr-5 pl-5">                    
        <v-row>
        <v-col class="pb-3">
            <h3>Actividades Culturales</h3>
        </v-col>
        </v-row>
        <v-row><v-divider></v-divider></v-row>
        <v-row v-if="keyTypesa">
        <v-col lg="2" class="pb-0 pt-0 " v-for="i in itemsTypeActivities">
            <!-- {{ i.AcuID }} -->
            <v-switch
                :label=" i.DedDescription ? i.DedDescription : i.TypeActivityName"
                v-model="i.AcuStatus" color="primary"
                @click ="saveswitchv(i)"
            ></v-switch>
        </v-col>
        </v-row>                
     </v-card>
</template>

<script>

import _visitApplication from "@/services/Technicalassistance/Visit/VisitActivityCulturalService";
import SSelectPlagues from "@/components/TecnicalAssistence/Plagues/SSelectPlagues";
import _sDefinition from "@/services/General/DefinitionService.js";

    export default {
        components: { 
            SSelectPlagues,
        },
        props: {
            Vst: { 
                type: Object, 
                default: 0 
            },
        },
        watch: {
            Vst(){
                // this.filter.VstID = this.Vst.VstID;
                
                // this.loadActv(this.Vst.VstID);
                this.loadDef();
            },
            keyTypesa(){
                console.log("en la mira")
            }
        },
        data() {
            return {
                statss: true,
                filter: {},
                itemsTypeActivities: [],
                keyTypesa : false
            };
        },
        mounted(){
            this.loadDef();

        },
        methods: {    
            loadDef(){
                this.keyTypesa = false;
                _sDefinition.definitiongroup(1204, 0, this.$fun.getUserID()).then((r) => {


                    r.data.forEach((item) => {                 
                        item.AcuStatus = false;
                        item.AcuID = 0;
                    });
                        
                    this.itemsTypeActivities = r.data;
                    // this.itemsCountInstallations = this.itemsTypeInstallations.length;

                    this.loadActv(this.Vst.VstID);
                    // --SAMIR comment
                });

            },
            saveswitchv(actcu){
                if (this.Vst.VstID == 0 || this.Vst.VstID == null) {
                    this.$fun.alert("Debe Seleccionar una Visita", "warning")
                    return;
                }
              
                actcu.VstID = this.Vst.VstID;
                actcu.TypeActivity = actcu.DedValue;
                actcu.SecStatus = 1;
                actcu.AcuObservation = "";
                // actcu.AcuID = 0;
                console.log("appl v", actcu)

                let obj = {
                    VstID: this.Vst.VstID,
                    TypeActivity: actcu.DedValue,
                    SecStatus: 1,
                    AcuObservation: "",
                    AcuID: actcu.AcuID,
                    AcuStatus: actcu.AcuStatus ? 1 : 0,

                }
                console.log("obj sav", obj)
                  
                _visitApplication.save(obj , this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                        this.$fun.alert("Guardado correctamente", "success")

                        this.loadDef();
                    }
                });
            },
            loadActv(vstID){
                
                console.log("loadActv", vstID)
                _visitApplication.pagination({ VstID: vstID } , this.$fun.getUserID()).then((r) => {
                    if (r.status == 200) {
                         
                        console.log("data de actividades", r)

                       
                        this.itemsTypeActivities.forEach((item) => {
                           // item.TypeActivity = item.DedValue;
                          
                            item.SecStatus = 1;
                            item.UsrCreateID = this.$fun.getUserID();
                            console.log("data de actividades Iitem", item)

                            r.data.forEach(element => {
 
                                if (item.DedValue == element.TypeActivity) {
                                    // item.AcuStatus = true;
                                    item.AcuStatus = element.AcuStatus == 1 ? true : false;
                                    item.AcuID = element.AcuID;
                                    console.log("coinciden", item)
                                    console.log("coinciden bd", element)

                                }
                            });
                        });

                        this.keyTypesa = true;
                        // this.itemsTypeActivities = this.itemsTypeActivities;
                        

                        console.log("this.itemsTypeActivities.", this.itemsTypeActivities)
                    }
                });
            }
        }
}
</script>