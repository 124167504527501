import Service from "../../Service";

const resource = "visit/";

export default {
    
    save(parameters, requestID) {
        return Service.post(resource + "saveRecomendationObs", parameters, {
            params: {
                requestID: requestID,
            },
        });
    },
    pagination(parameters, requestID) {
        return Service.post(resource + "paginationRecomendationObs", parameters, {
            params: { requestID }
        });
    }
};