<template>
    <v-card>
        <v-row>
            <v-col>                  
                <s-crud
                    title="Actividades Realizadas"
                    :config="config"
                    edit
                    add                   
                    @save="save($event)"                
                    remove
                    :filter="filter"
                    search-input
                    ref="crudPhenol" 
                    height="170">            
                    <template slot-scope="props">
                        <v-card>                          
                           <v-container>
                            <v-row>
                                <v-col lg="4"   cols="12">
                                <s-select-definition
                                    :def="1204"
                                    v-model="objTypeActivity"
                                    add
                                    @input="inputDataActivities($event)"
                                    label="Actividad"
                                    return-object/>
                                </v-col>
                                <v-col lg="4" cols="12">
                                    <s-date
                                        label="Fecha Inicio"
                                        v-model="props.item.PacBeginDate" /> 
                                </v-col>
                                <v-col lg="4" cols="12">
                                    <s-date 
                                        label="Fecha Fin" 
                                        v-model="props.item.PacEndDate"/> 
                                </v-col>
                            </v-row>  
                            
                            <v-row v-if="objTypeActivity.DedValue==1">
                                 <!-- @input="inputTypeSistema($event)" -->
                                    
                                <v-col sm="4" cols="4">
                                    <s-select-definition
                                        label="Sistema" 
                                        v-model="props.item.TypeIrrigation"
                                        :def="1352">
                                    </s-select-definition>
                                </v-col>
                                <v-col sm="4" cols="4">
                                    <s-select-definition
                                        label="Tipo sistema"                                    
                                        v-model="props.item.TypeDetailIrrigation"
                                        :def="1353">
                                    </s-select-definition>
                                </v-col>
                                <v-col sm="4" cols="4">
                                    <s-text 
                                        v-model="props.item.PacFuelCost" 
                                        label="Gasto de combustible" 
                                        decimal 
                                        ref="refPacFuelCost"/> 
                                </v-col>
                            </v-row>
                            <v-row v-if="objTypeActivity.DedValue==1">
                                <v-col sm="4" cols="6">
                                    <s-text 
                                        v-model="props.item.PacFlowIrrigation" 
                                        label="Caudal de riego (L/s)" 
                                        decimal 
                                        ref="refPacFlowIrrigation"/> 
                                </v-col>
                                <v-col sm="4" cols="6">
                                    <s-text 
                                        v-model="props.item.PacTimeIrrigation" 
                                        label="Tiempo de riego (H)" 
                                        decimal 
                                        ref="refPacTimeIrrigation"/> 
                                </v-col>
                            </v-row>
                           
                            
                            <v-row v-if="objTypeActivity.DedValue==2" >
                                <v-col sm="4" cols="6">
                                    <s-text 
                                        v-model="props.item.PacNumberOfTrees" 
                                        number label="Número de arboles podados" 
                                        ref="refPacNumberOfTrees" /> 
                                </v-col>
                            </v-row>
                             
                             
                            <v-row v-if="objTypeActivity.DedValue==3">
                                <v-col sm="4" cols="6">
                                    <!-- @input="inputTypeDeshi($event)" -->
                                    <s-select-definition
                                        label="Tipo de deshierbo"                                     
                                        v-model="props.item.TypeWeeding"                                   
                                        :def="1354">
                                    </s-select-definition>
                                </v-col>
                                <v-col sm="4" >
                                    <s-text 
                                        v-if="props.item.TypeWeeding == 3"
                                        v-model="props.item.PacAmountFuelGallons" 
                                        label="Cantidad de combustible (Gal)" 
                                        decimal 
                                        ref="refPacAmountFuelGallons"/> 
                                </v-col>
                            </v-row>
                            
                            
                            <v-row v-if="objTypeActivity.DedValue==4">
                                <v-col sm="4" cols="6">
                                    <s-select-definition
                                        label="Tipo de trampa"                                   
                                        v-model="props.item.TypeTrap"
                                        :def="1355">
                                    </s-select-definition>
                                </v-col>
                                <v-col sm="4">
                                    <s-text 
                                        v-model="props.item.PacNumberTrap" 
                                        label="Número de trampas" 
                                        number 
                                        ref="refPacNumberTrap"/> 
                                </v-col>
                            </v-row>
                            
                           </v-container>                 
                        </v-card>
   
                            
                    </template>
                </s-crud>
      
            </v-col>
        </v-row>
      
    </v-card>
</template>

<script>

import _visitApplication from "@/services/Technicalassistance/Visit/VisitPerformedActivitiesService";
import SSelectPlagues from "@/components/TecnicalAssistence/Plagues/SSelectPlagues";

    export default {
        components: { 
            SSelectPlagues,
        },
        props: {
            Vst: { 
                type: Object, 
                default: 0 
            },
        },
        watch: {
            Vst(){
                this.filter.VstID = this.Vst.VstID;
                console.log("this.Vst.", this.Vst);
            }
        },
        data() {
            return {
                filter: { 
                    VstID: null,               
                },
                config: {
                    model: {
                        PacID: "ID"
                    },
                    service: _visitApplication,
                    headers: [
                        { text: 'ID', value: 'PacID' },
                     
                        // { text: 'Detalles', value: 'viewDetails'},
                        { text: 'Actividad', value: 'TypeActivityName' },
                        { text: 'Fecha Inicio', value: 'PacBeginDate' },
                        { text: 'Fecha Fin', value: 'PacEndDate' },
                        { text: 'Acciones', value: 'Actions', align: 'center', width: '90',sortable: false},
                           
                    ],
                },
                numberActivities: 0,
                modalAddActivities: false,
                objTypeActivity: {},
            };
        },
        mounted(){
            this.filter.VstID = this.Vst.VstID;
                console.log("mounted activid", this.Vst);
        },
        methods: {    
            openModelNewActivities (){

                if(this.numberActivities != 0){
                this.modalAddActivities = true;
                } else {
                this.addActivities();
                }


            },

            addActivities() {

            if (!this.objTypeActivity) {
            this.$fun.alert("Seleccione Actividad", "warning");
            return;
            }

            if(this.modalAddActivities) {

            switch (this.numberActivities) {
                case 1 : 

                if (this.validateSistem2) {
                    if(this.objActiviy.PacFuelCost <= 0){
                    this.$refs.refPacFuelCost.error('Debe Ingresar el gasto del combustible',"error");
                    return;
                    }

                    if(this.objActiviy.PacFlowIrrigation <= 0){
                    this.$refs.refPacFlowIrrigation.error('Debe ingresar el caudal del riego', "error");
                    return;
                    }
                    if(this.objActiviy.PacTimeIrrigation <= 0) {
                    this.$refs.refPacTimeIrrigation.error('Debe Ingresar el tiempo de riego', "error");
                    return;
                    }

                } else {

                    
                    if(this.objActiviy.PacFlowIrrigation <= 0){
                    this.$refs.refPacFlowIrrigation.error('Debe ingresar el caudal del riego', "error");
                    return;
                    }
                    if(this.objActiviy.PacTimeIrrigation <= 0) {
                    this.$refs.refPacTimeIrrigation.error('Debe Ingresar el tiempo de riego', "error");
                    return;
                    }

                }
                
                
                break;
                case 2 : 
                if(this.objActiviy.PacNumberOfTrees <= 0){
                    this.$refs.refPacNumberOfTrees.error('Debe Ingresar número de arboles podados', "error");
                    return;
                }
                break;
                case 3:
                if(this.validateDeshi){
                    if(this.objActiviy.PacAmountFuelGallons <= 0){
                    this.$refs.refPacAmountFuelGallons.error('Debe ingresar la cantidad de galones', "error");
                    return;
                    }
                }
                break;
                case 4:
                if(this.objActiviy.PacNumberTrap <= 0){
                    this.$refs.refPacNumberTrap.error('Debe ingresar la cantidad trampas', "error");
                    return;
                }
                break;
            }
            }


            this.itemsActivities.push({

            VstID : this.visitSchedule.VstID ? this.visitSchedule.VstID : 0, 

            TypeIrrigation: this.objActiviy.TypeIrrigation ? this.objActiviy.TypeIrrigation.DedValue : null,
            TypeDetailIrrigation: this.objActiviy.TypeDetailIrrigation ? this.objActiviy.TypeDetailIrrigation.DedValue : null,
            PacFlowIrrigation: this.objActiviy.PacFlowIrrigation ? this.objActiviy.PacFlowIrrigation : null ,
            PacTimeIrrigation: this.objActiviy.PacTimeIrrigation ? this.objActiviy.PacTimeIrrigation : null,
            PacFuelCost: this.objActiviy.PacFuelCost ? this.objActiviy.PacFuelCost : null,
            PacNumberOfTrees: this.objActiviy.PacNumberOfTrees ? this.objActiviy.PacNumberOfTrees : null,
            TypeWeeding: this.objActiviy.TypeWeeding ? this.objActiviy.TypeWeeding.DedValue : null,
            PacAmountFuelGallons : this.objActiviy.PacAmountFuelGallons ? this.objActiviy.PacAmountFuelGallons : null,
            TypeTrap : this.objActiviy.TypeTrap ? this.objActiviy.TypeTrap.DedValue : null,
            PacNumberTrap : this.objActiviy.PacNumberTrap ? this.objActiviy.PacNumberTrap : null,

            TypeIrrigationName : this.objActiviy.TypeIrrigation ? this.objActiviy.TypeIrrigation.DedDescription : null,
            TypeDetailIrrigationName : this.objActiviy.TypeDetailIrrigation ? this.objActiviy.TypeDetailIrrigation.DedDescription : null,
            TypeTrapName :  this.objActiviy.TypeTrap ? this.objActiviy.TypeTrap.DedDescription : null,
            TypeWeedingName : this.objActiviy.TypeWeeding ? this.objActiviy.TypeWeeding.DedDescription : null,


            TypeActivity: this.objTypeActivity ? this.objTypeActivity.DedValue : null,
            Line: this.itemsActivities.length + 1,
            PacBeginDate: this.ActBeginDate,
            PacEndDate: this.ActEndDate,
            TypeActivityName: this.objTypeActivity ? this.objTypeActivity.DedDescription : null,
            SecStatus: 1,
            UsrCreateID: this.$fun.getUserID(),
            });

            // this.itemsActivities = [];

            this.objActiviy = {};
            this.modalAddActivities=false;

            // console.log('actividades a guardar', this.itemsActivities)
            },
            inputDataActivities(items){
                console.log("inputDataActivities", items)
                
            }
            ,     
            save(evalu){
                if (this.Vst.VstID == 0 || this.Vst.VstID == null) {
                    this.$fun.alert("Debe Seleccionar una Visita", "warning")
                    return;
                }
              
                evalu.VstID = this.Vst.VstID;
                evalu.SecStatus = 1

                evalu.TypeActivity = this.objTypeActivity.DedValue;

                if (evalu.TypeActivity == null) {
                    this.$fun.alert("El Tipo de actividad es obligatorio.", "warning")
                    return;
                }
 
                console.log("appl v", evalu)
                evalu.save().then((r) => {
                    this.$emit("reload");
                });
            }
        }
    }
</script>