import Service from "../../Service";

const resource = "visit/";

export default {
    
    save(parameters, requestID) {
        return Service.post(resource + "saveInstallationEvaluation", parameters, {
            params: {
                requestID: requestID,
            },
        });
    },
    list(parameters, requestID) {
        return Service.post(resource + "listVisitInstallationEvaluation", parameters, {
            params: { requestID }
        });
    }
};